/*
 * Basket Page
 */
export const updateItemQuantityError =
  'Ocorreu um erro ao atualizar a quantidade do produto.';
export const updateItemOutOfStock =
  'A quantidade desejada do produto é maior que a sua quantidade em estoque.';
export const fetchBasketError = 'Ocorreu um erro ao recuperar a sacola.';
export const deleteWarrantyError = 'Ocorreu um erro ao remover a garantia do produto.';
export const deleteServiceError = 'Ocorreu um erro ao remover o serviço do produto.';
export const deleteItemError = 'Ocorreu um erro ao remover o produto.';
export const storeError = 'Ocorreu um erro ao buscar a loja.';
export const failFetchShipment = 'Não foi possível calcular o frete. Tente novamente.';
export const failShipmentNotFound = 'Não encontramos o CEP informado.';
export const maxItemsBasket =
  'Você atingiu o limite de produtos na sacola de compras aqui do site. Caso queira adquirir uma quantidade superior, siga as instruções: Pessoas Físicas - <a href="https://atendimento.magazineluiza.com.br/hc/pt-br?open=chat_televendas" target="_blank">Chat Online</a>; Pessoas Jurídicas - Cotação por email: corporativo@magazineluiza.com.br';
export const incompleteRegister =
  'O seu cadastro está incompleto, por favor complete o seu cadastro para continuar.';
export const soldOutWarning = 'Produto esgotado.';
export const unavailableWarning = 'Entrega deste produto não disponível para o CEP';
export const itemNotAllowedForCompanies = 'Produto indisponível para compras com CNPJ.';
export const checkSimilar = 'Ver produtos disponíveis';

/*
 * Login Page
 */
export const invalidLogin = 'Insira um CPF, CNPJ ou E-mail válido.';
export const invalidIdentification = 'Insira um CPF ou E-mail válido.';
export const invalidLoginOrPassword = 'Verifique o login e a senha para continuar.';
export const invalidLoginOrPasswordTitle = 'Dados inválidos';
export const invalidLoginOrPasswordDescription =
  'Por favor, verifique e tente novamente.';
export const invalidLoginEnabledRedirect = 'Insira um CPF ou E-mail válido.';
export const customerNotFound = 'E-mail ou CPF não cadastrado.';
export const customerBlocked =
  'Ops, encontramos um problema no seu cadastro. Por favor, entre em contato com o nosso SAC.';
export const blankPassword = 'Insira a senha.';
export const shortPassword = 'Senha muito curta.';
export const invalidEmail = 'Insira um e-mail válido.';
export const loginFail = 'Não foi possível completar o login. Tente novamente.';
export const inactiveLogin =
  'Seu cadastro está inativo. Fale com a gente pelo 0800 310 0002 que a gente te ajuda!';

export const customerNotRegistered = 'E-mail, CPF ou CNPJ não registrado no site.';
export const customerNotRegisteredEnabledRedirect =
  'E-mail ou CPF não registrado no site.';

/*
 * Social Login
 */
export const socialLoginNotFound =
  'Não foi possível encontrar o cadastro do usuário. Tente novamente.';
export const socialLoginFail =
  'Não foi possível conectar a rede social. Tente novamente.';
export const saveSocialLoginFail = 'Não foi possível salvar o perfil. Tente novamente.';

/*
 * Signup and Customer Area Box
 */
export const emailAlreadyRegistered = 'Já existe uma conta com esse e-mail.';
export const emailAlreadyRegisteredCustomerArea =
  'E-mail já vinculado a uma conta. Por favor informe outro.';
export const failSignupAvailable =
  'Não foi possível encontrar o e-mail. Tente novamente.';
export const blankSignupEmail = 'O e-mail não foi digitado corretamente.';
export const invalidEmailCustomerArea = 'O e-mail informado não é válido.';
export const invalidEmailConfirmCustomerArea = 'Os e-mails não coincidem.';
export const blankSignupEmailCustomerArea = 'Por favor, informe um e-mail.';
export const blockEmail =
  'E-mail informado não é válido. Por favor, informe outro e-mail.';

/*
 * Signup Page
 */
export const CPFAlreadyRegistered = 'Já existe uma conta com esse CPF.';
export const CNPJAlreadyRegistered = 'Já existe uma conta com esse CNPJ.';
export const failFetchCpfOrCnpjAvailable =
  'Não foi possível encontrar o CPF/CNPJ. Tente novamente.';
export const invalidCPF = 'Insira um CPF válido.';
export const invalidFullName = 'Insira seu nome completo.';
export const invalidFullNameOnCard = 'Insira seu nome impresso no cartão.';
export const invalidTelephone = 'Insira o telefone corretamente.';
export const invalidTelephoneCustomerArea = 'O telefone informado não é válido.';
export const invalidTelephoneConfirmCustomerArea = 'Os telefones não coincidem.';
export const numberIsTheSame =
  'Este telefone já está vinculado a sua conta. Por favor, informe outro.';
export const invalidBirthDate = 'Insira a data de nascimento corretamente.';
export const invalidBirthDateOfAge =
  'Menores de 18 anos não estão autorizados a efetuar o cadastro em nosso site. Em caso de dúvidas entre em contato através do email atendimento.site@magazineluiza.com.br';
export const wrongPasswordLength = 'A senha deve ter de 6 a 20 caracteres.';
export const invalidPassword = 'Insira uma senha.';
export const invalidZipcode = 'Insira um CEP válido.';
export const addressNeeded = 'Você precisa buscar o endereço através do CEP.';
export const invalidAddress = 'Insira um endereço válido.';
export const invalidNumber = 'Insira um número válido.';
export const invalidCity = 'Insira uma cidade válida.';
export const invalidState = 'Insira um estado válido.';
export const invalidNeighbourhood = 'Insira um bairro válido.';
export const invalidReference =
  'Para facilitar a entrega, insira um ponto de referência válido.';
export const failSignup = 'Não foi possível completar o cadastro. Tente novamente.';
export const failFetchAddress = 'Não foi possível encontrar este CEP. Tente novamente.';

/*
 * Edit Registration Page
 */
export const failFetchCustomer =
  'Não foi possível buscar suas informações. Tente novamente.';
export const invalidOldPassword =
  'Sua senha atual está incorreta. Corrija e tente novamente.';
export const oldPasswordRequired = 'Insira sua senha atual.';

/*
 * Addresses Page
 */
export const invalidReceiver = 'Insira o nome completo do destinatário.';
export const nameWithNumbers = 'O nome do destinatário não deve conter números.';

/*
 * SignupPJ
 */
export const invalidCNPJ = 'Insira um CNPJ válido.';
export const invalidCompanyName = 'Insira a Razão Social corretamente.';
export const invalidFantasyName = 'Insira o nome fantasia corretamente.';
export const invalidStateRegistration = 'Insira a Inscrição Estadual corretamente.';
export const invalidPosition = 'Insira o cargo corretamente.';

/*
 * Address Page
 */
export const failSetAddress = 'Não foi possível escolher o endereço. Tente novamente.';
export const incompleteSetAddress =
  'O cadastro do endereço está incompleto, por favor complete para continuar.';
export const failSaveAddress = 'Não foi possível salvar o endereço. Tente novamente.';

/*
 * Expired or Unauthorized view
 */
export const expired = 'Sua sessão expirou. Entre novamente.';

/*
 * Order
 */
export const failPlaceOrder =
  'Não foi possível fechar seu pedido. Por favor verifique os dados informados e clique em concluir pedido novamente.';
export const failFetchInstallments =
  'Houve um problema ao buscar a lista de parcelamento. Tente novamente.';
export const failFetchCardList =
  'Houve um problema ao buscar a lista de cartões salvos. Tente novamente.';
export const flagNotSelected = 'Selecione uma bandeira.';
export const invalidInstallmentsNumber = 'Selecione um parcelamento.';
export const invalidInstallmentsFlag =
  'Selecione a bandeira pra seguir com o parcelamento.';
export const invalidCardNumberForInstallments =
  'Preencha um número de cartão válido para ver o parcelamento.';
export const invalidCVC = 'Código inválido.';
export const invalidCardNumber = 'Insira o número do cartão.';
export const invalidLuhnCardNumber = 'Número de cartão inválido.';
export const invalidVirtualDebitValidCreditNumber =
  'Dados não batem com o cartão. Selecione a opção "Novo cartão de crédito" e digite novamente.';
export const invalidCreditValidVirtualDebitNumber =
  'Dados não batem com o cartão. Selecione a opção "Cartão de débito virtual Caixa" e digite novamente.';
export const cardNumberNotAllowed = 'Número de cartão não permitido.';
export const invalidExpirationDate = 'Data inválida.';

/*
 * Delivery Page
 */
export const failFetchDeliveryPackages =
  'Houve um problema ao buscar os pacotes da entrega. Tente novamente.';
export const failSetDeliveryType =
  'Houve um problema ao escolher a entrega. Reveja as opções e tente novamente por favor.';
export const failFetchStores =
  'Houve um problema ao buscar as lojas para a entrega. Tente novamente.';
export const invalidOtherWillPickup =
  'É necessário informar os dados da pessoa que irá retirar em loja.';
export const invalidNoPickupStoreSelected =
  'Para continuar, escolha uma loja ou altere a opção de entrega.';
export const failSaveGiftCard =
  'Não foi possível salvar o cartão de presente. Tente novamente.';
export const failRemoveGiftCard =
  'Não foi possível remover o cartão de presente. Tente novamente.';
export const invalidGiftCardFields =
  'Preencha os campos do cartão de presente corretamente.';
export const invalidName = 'Insira o nome corretamente.';
export const invalidMessage = 'Insira a mensagem corretamente.';
export const changedAddress =
  'Atenção: Como o endereço foi alterado, por favor revise todas as opções de entrega antes de continuar.';
export const outOfStock =
  'Houve um problema ao calcular a entrega. Reveja os produtos na sacola.';

/*
 * Receipt page
 */
export const failOrderNotFound = 'Não foi possível achar o pedido.';
export const failFetchOrder =
  'Ocorreu um erro ao tentar achar o pedido. Tente novamente.';
export const failNewsletterOption =
  'Ocorreu um erro ao tentar cadastrar o e-mail. Tente novamente por favor.';

/*
 * Delivery deadline conditions. Present in "Delivery", "Payment" and "ConfirmOrder" pages
 */
export const deliveryTimeConditions =
  'O prazo de entrega inicia-se no 1º dia útil após a confirmação do pagamento. Esse procedimento costuma ocorrer em até 24 horas, mas tem período máximo para acontecer de até 48 horas (pagamento no cartão). Se o pagamento for realizado por boleto bancário, o banco tem o prazo de até três dias úteis para confirmar.';

/*
 * Same day pickup in store conditions
 */
export const sameDayPickupConditions =
  'O prazo de entrega, para retiradas no mesmo dia, inicia-se após a aprovação do pagamento, desde que ela ocorra até as 14h30.';
export const sameDayAvailability =
  'Quando o produto estiver disponível para retirada nós te avisaremos, por favor aguarde.';
export const boldExtendedDelivery = 'Retire em 2 horas';
export const normalExtendedDelivery = 'após a aprovação da compra.**';

/*
 * Default delivery conditions
 */
export const deliveryOneHour = 'Entrega em até 1 hora';
export const deliveryOneHourPaymentConfirmation = 'após a aprovação da compra.***';
export const deliveryOneHourConditions =
  'O prazo de entrega em até 1 hora, inicia-se após a aprovação do pagamento, desde que ela ocorra até as 17h15, de segunda à sexta feira.';

/*
 * CVV Types
 */
export const commonCard =
  'Código de 3 a 4 dígitos impresso na frente ou no verso do seu cartão.';
export const virtualDebitElo = 'Acesse o app da Caixa e gere o CVV';

/*
 * Promocode
 */
export const promocodeError = 'Este cupom não é válido. Por favor, tente novamente.';
export const promocodeInvalid = 'Este cupom não é válido. Por favor, tente novamente.';
export const promocodeMaxQuantity =
  'Já atingimos o limite de usos deste cupom. Continue sua compra e aproveite nossas ofertas. :)';
export const promocodeExpired =
  'Este cupom já expirou. Continue sua compra e aproveite nossas ofertas. :)';
export const promocodeWithoutDiscount = 'Cupom não elegível para essa compra.';

/*
 * Generic Error
 */
export const genericError = 'Ocorreu um erro. Tente novamente por favor.';

/*
 * Reset Password
 */
export const invalidCellphone = 'O celular informado não é válido.';
export const invalidCellPhoneMaxErrorAttempts = `${invalidCellphone} Recupere sua senha por e-mail, ou, entre em contato com nosso SAC.`;
export const invalidConfirmEmail = 'O e-mail informado não é válido.';
export const invalidConfirmEmailMaxErrorAttempts = `${invalidConfirmEmail} Recupere sua senha por mensagem, ou, entre em contato com nosso SAC.`;
export const invalidConfirmEmailMaxErrorAttemptsWithoutPhone = `${invalidConfirmEmail} Entre em contato com nosso SAC.`;
export const requiredPin = 'Insira um código válido.';
export const invalidPin = 'O código informado não é válido.';

/*
 * Otp CODE
 */
export const otpExpired = 'Código expirado. Envie novamente ou altere o meio de envio.';
export const maxTries =
  'Número de tentativas excedido. Aguarde para enviar um novo código.';
export const invalidOtpCode =
  'Código inválido: Você digitou um código diferente do enviado.';
export const otpGeneratorFail400 =
  'Não foi possível enviar o código. Verifique seu e-mail ou número de telefone e tente novamente.';
export const otpGeneratorFail500 =
  'Não foi possível enviar o código. Por favor, tente novamente.';

/*
 * maximum of items per sku
 */
export const maxItemsPerSkuNotification = 5;

/*
 * Customer Data Success Data Change
 */
export const successBothDataChange =
  'Alteração em andamento, seu novo e-mail e telefone podem levar alguns minutos para ser atualizados.';
export const successEmailChange =
  'Alteração em andamento, seu novo e-mail pode levar alguns minutos para ser atualizado.';
export const successTelephoneChange =
  'Alteração em andamento, seu novo telefone pode levar alguns minutos para ser atualizado.';
export const successDefaultDataChange =
  'Alteração em andamento, seu novo dado pode levar alguns minutos para ser atualizado.';
