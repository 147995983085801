/* eslint-disable no-useless-escape */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { datadogRum } from '@datadog/browser-rum';

let tmxLoadingTimeout = null;
const tmxTimeout =
  window.MAGALU_CHANNEL?.channel_configs?.tmx_timeout || 5000;
const tmxOrgId =
  window.MAGALU_CHANNEL?.channel_configs?.tmx_org_id || '5cfbehmb';

class Threatmetrix extends PureComponent {
  static propTypes = {
    toggleProfilingIsComplete: PropTypes.func.isRequired,
    hideProfilingLoading: PropTypes.func.isRequired,
    handleLogin: PropTypes.func.isRequired,
    profilingIsComplete: PropTypes.bool.isRequired,
    isTryingLogin: PropTypes.bool.isRequired
  };

  finishedProfiling = () => {
    this.props.toggleProfilingIsComplete(true);
    this.props.hideProfilingLoading();

    if (this.props.profilingIsComplete && this.props.isTryingLogin) {
      this.props.handleLogin();
    }
  }

  componentWillMount() {
    const start = Date.now();

    window.threatmetrix?.profile('d.mlcdn.com.br', tmxOrgId, window.tmx_sessionID);

    this.props.toggleProfilingIsComplete(false);

    tmxLoadingTimeout = setTimeout(() => {
      this.finishedProfiling(start)
      datadogRum.addAction('TMX_PROFILINIG_TIMEOUT', {
        duration: Date.now() - start,
      });
    }, tmxTimeout);

    window.tmx_profiling_complete = (sessionId) => {
      this.finishedProfiling(start)

      const duration = Date.now() - start

      datadogRum.addAction('TMX_PROFILINIG_COMPLETE_EXECUTION_TIME', {
        duration,
        sessionId,
        message: `TMX Profiling foi carregado em ${duration}ms`,
      });

      clearTimeout(tmxLoadingTimeout);
      document.cookie = 'tmx_national_sessionId=' + sessionId;
    };
  };

  componentWillUnmount() {
    clearTimeout(this.tmxLoadingTimeout);
    this.props.hideProfilingLoading();
  }

  render() {
    return null;
  }
}

export default Threatmetrix;
