import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { cardDescription, pixDescription } from 'constants/price-descriptions';
import InternationalTaxICMSModal from 'components/InternationalTaxICMSModal/InternationalTaxICMSModal';
import InternationalImportTaxModal from 'components/InternationalImportTaxModal/InternationalImportTaxModal';
import PromoCodeTag from 'components/HighlightPromoCode/PromoCodeTag/PromoCodeTag';
import PromoCodeModal from 'components/HighlightPromoCode/PromoCodeModal';
import * as promocodeActions from 'reducers/promocode';
import withModal from 'hocs/withModal/withModal';
import { toBRL, toBRLwithZero } from 'utils/currency';
import { classNameWithModifier } from 'utils/classnames';
import isCouponJourneyEnable from 'utils/isCouponJourneyEnable';
import { getCouponInBasket } from 'utils/couponHelpers';
import { isLogged } from 'utils/session';
import { redirectToLogin } from 'utils/redirect';

import './BasketContinueFloating.scss';
import { openCouponModalActions } from '../helpers';

const mapStateToProps = ({ basket, routing }) => ({
  discounts: basket.basket.discounts,
  promocode: basket.basket.promocode,
  location: routing.location,
});

const mapDispatchToProps = {
  promocodeEventVirtualPageView: promocodeActions.promocodeEventVirtualPageView,
  promocodeEventCustom: promocodeActions.promocodeEventCustom,
};

@connect(mapStateToProps, mapDispatchToProps)
class BasketContinueFloating extends Component {
  static propTypes = {
    promocodeEventVirtualPageView: PropTypes.func.isRequired,
    promocodeEventCustom: PropTypes.func.isRequired,
    discounts: PropTypes.shape({
      promocode: PropTypes.string,
    }),
    promocode: PropTypes.string,
    location: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    totals: PropTypes.shape({
      to: PropTypes.string.isRequired,
      cash: PropTypes.string,
      shipment: PropTypes.string,
      amountWithoutDiscount: PropTypes.string,
    }),
    disabled: PropTypes.bool.isRequired,
    zipcode: PropTypes.string,
    products: PropTypes.array,
  };
  constructor(props) {
    super(props);
    this.state = {
      isInternationalTaxICMSModalOpen: false,
      isInternationalImportTaxModalOpen: false,
      isCouponModalOpen: false,
    };
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  componentClassName() {
    const className = 'BasketContinueFloating';
    const modifier = this.props.show ? 'show' : '';
    return classNameWithModifier(className, modifier);
  }

  totalServicesCount() {
    const listProducts = this.props.products;
    return listProducts.reduce((total, product) => {
      return total + (product.services ? product.services.length : 0);
    }, 0);
  }

  servicesCost() {
    const listProducts = this.props.products;
    const totalServices = listProducts
      .flatMap((product) => product.services)
      .reduce((total, service) => total + parseFloat(service.price), 0);
    return toBRL(totalServices);
  }

  productPlural() {
    const onlyProductsCount = this.props.products || 0;
    return onlyProductsCount.length > 1
      ? `Produtos (${onlyProductsCount.length} itens)`
      : `Produto (${onlyProductsCount.length} item)`;
  }

  servicesPlural() {
    const totalServices = this.totalServicesCount();
    return totalServices > 1
      ? `Serviços (${totalServices} itens)`
      : `Serviço (${totalServices} item)`;
  }

  shipmentCostText() {
    const isZipcodeFilled = Boolean(this.props.zipcode);
    const totalDelivery = this.props.totals.shipment;

    return (
      (isZipcodeFilled &&
        ((totalDelivery && totalDelivery !== '0' && toBRL(totalDelivery)) || (
          <span className="BasketAddress-floating-free"> Grátis </span>
        ))) || <span>&nbsp;&nbsp;</span>
    );
  }

  handleInternationalTaxICMSModalToggle = () => {
    this.setState({
      isInternationalTaxICMSModalOpen: !this.state.isInternationalTaxICMSModalOpen,
    });
  };

  handleInternationalImportTaxModalToggle = () => {
    this.setState({
      isInternationalImportTaxModalOpen: !this.state.isInternationalImportTaxModalOpen,
    });
  };

  openCouponModal = () => {
    const { location } = this.props;
    const { router } = this.context;

    if (!isLogged()) {
      return redirectToLogin(router, '/', location);
    }

    openCouponModalActions(this.props);
    this.setState({ isCouponModalOpen: true });
  };

  closeCouponModal = () => this.setState({ isCouponModalOpen: false });

  render() {
    const {
      totals: {
        cash,
        checkoutAmountDescription,
        to,
        amountDescription,
        amountWithoutDiscount,
        icms,
        importValue,
      },
      promocode,
      discounts,
    } = this.props;

    const {
      isInternationalTaxICMSModalOpen,
      isInternationalImportTaxModalOpen,
      isCouponModalOpen,
    } = this.state;

    return (
      <div className={this.componentClassName()}>
        <InternationalTaxICMSModal
          isOpen={isInternationalTaxICMSModalOpen}
          toggle={this.handleInternationalTaxICMSModalToggle}
        />
        <InternationalImportTaxModal
          isOpen={isInternationalImportTaxModalOpen}
          toggle={this.handleInternationalImportTaxModalToggle}
        />
        <div className="BasketContinueFloating-table">
          {isCouponJourneyEnable() && (
            <span className="BasketContinueFloating-line-coupon">
              <div className="w-full">
                <PromoCodeTag
                  type="big"
                  promo={{
                    message: getCouponInBasket(promocode, discounts)
                      ? 'Cupom aplicado'
                      : 'Tem um código de cupom?',
                  }}
                  button={{
                    onClick: this.openCouponModal,
                    label: getCouponInBasket(promocode, discounts)
                      ? 'Ver cupons'
                      : 'Inserir',
                  }}
                />
                <PromoCodeModal
                  open={isCouponModalOpen}
                  onClose={this.closeCouponModal}
                />
              </div>
            </span>
          )}
          <div className="BasketContinueFloating-line">
            <span className="BasketContinueFloating-title-value">Frete total</span>
            <span className="BasketContinueFloating-value-delivery">
              {this.shipmentCostText()}
            </span>
          </div>

          {!!promocode && discounts?.promocode && (
            <div className="BasketContinueFloating-line">
              <span className="BasketContinueFloating-title-value">Cupom</span>
              <span className="BasketAddress-floating-free">
                {' '}
                - {toBRLwithZero(discounts?.promocode)}{' '}
              </span>
            </div>
          )}

          <div className="BasketContinueFloating-line">
            <span className="BasketContinueFloating-title">{this.productPlural()}</span>
            <span className="BasketContinueFloating-value">
              {toBRL(amountWithoutDiscount)}
            </span>
          </div>

          {this.totalServicesCount() > 0 && this.servicesPlural() && (
            <div className="BasketContinueFloating-line">
              <span className="BasketContinueFloating-title">
                {this.servicesPlural()}
              </span>
              <span className="BasketContinueFloating-value">{this.servicesCost()}</span>
            </div>
          )}

          {importValue && (
            <div className="BasketContinueFloating-line">
              <span className="BasketContinueFloating-title-value">
                Imposto de Importação{' '}
                <span
                  className="ti ti-help-outline cursor-pointer"
                  onClick={this.handleInternationalImportTaxModalToggle}
                />
              </span>
              <span className="BasketContinueFloating-value-delivery">
                {toBRL(importValue)}
              </span>
            </div>
          )}

          {icms && (
            <div className="BasketContinueFloating-line">
              <span className="BasketContinueFloating-title-value">
                ICMS{' '}
                <span
                  className="ti ti-help-outline cursor-pointer"
                  onClick={this.handleInternationalTaxICMSModalToggle}
                />
              </span>
              <span className="BasketContinueFloating-value-delivery">{toBRL(icms)}</span>
            </div>
          )}

          <div className="BasketContinueFloating-separator" />

          <div className="BasketContinueFloating-line">
            <span className="BasketContinueFloating-titleTotal">Total:</span>
            <span className="BasketContinueFloating-value-shipment">
              <p>{`${toBRL(cash)} ${checkoutAmountDescription || pixDescription}`}</p>
              <p>{`ou ${toBRL(to)} ${amountDescription || cardDescription}`}</p>
            </span>
          </div>
        </div>

        <button
          className="BasketContinue-button"
          disabled={this.props.disabled}
          onClick={this.props.handleClick}
          data-ga='{"category": "Carrinho", "action": "Continuar", "label": "floating"}'>
          Continuar
        </button>
      </div>
    );
  }
}

export default withModal(BasketContinueFloating);
