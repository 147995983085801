import React, { Component } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import cookies from 'browser-cookies';
import { ENABLE_DATADOG_REPORT } from 'constants/cookies';

class DatadogTracker extends Component {
  lastPathname = window.location.pathname;

  componentDidMount() {
    if (!cookies.get(ENABLE_DATADOG_REPORT)) {
      return;
    }

    this.initDatadog();
    this.trackPageView();
    this.startTracking();
  }

  componentWillUnmount() {
    this.stopTracking();
  }

  initDatadog = () => {
    datadogRum.init({
      applicationId: '5a1a402a-f5e3-4e99-b4cd-184690dc868c',
      clientToken: 'pub872f37ede88dff59e13cbc3dfd514d49',
      site: 'us5.datadoghq.com',
      service: 'national-frontend',
      env: 'production',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      defaultPrivacyLevel: 'mask-user-input',
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      actionNameAttribute: 'dd-rum',
    });
  };

  trackPageView = () => {
    const currentPath = window.location.pathname;
    if (this.lastPathname !== currentPath) {
      this.lastPathname = currentPath;
      datadogRum.startView(currentPath);
    }
  };

  startTracking = () => {
    this.interval = setInterval(this.trackPageView, 500);
  };

  stopTracking = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

export default DatadogTracker;
